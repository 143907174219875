<template>
  <div class="cd-section" id="pricing">
    <!--     *********    PRICING 4     *********      -->
    <div class="pricing-4" id="pricing-4">
      <div class="container">
        <div class="row">
          <div class="col-md-6 ml-auto mr-auto text-center">
            <h2 class="title">Pick a Sponsorship Level</h2>
            <h5>
              We will customize your sponsorship sign with your company logo or
              personalized message.
            </h5>
            <info-section
              type="primary"
              class="mt-5"
              icon="now-ui-icons ui-1_email-85"
            >
              <h4 class="info-title">Interested?</h4>
              <h5 class="description">
                Email Us us no later than August 22nd with your level of
                interest and we will contact you for the specifics needed for
                customizing your sign.
              </h5>
              <p>
                <strong
                  ><a href="mailto:caring@toddnagelopen.com"
                    >caring@toddnagelopen.com</a
                  ></strong
                >
              </p>
            </info-section>
            <div class="section-space"></div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <card type="pricing" plain>
              <h3 class="category">SPF50 Level</h3>
              <img
                class="rounded img-raised"
                alt="Raised Image"
                src="img/SPF50-Hole-Sponsor.png"
              />
              <h3 class="card-title">
                <small>$</small>
                100
              </h3>
            </card>
          </div>
          <div class="col-md-4">
            <card type="pricing" plain>
              <h3 class="category">SPF70 Level</h3>
              <img
                class="rounded img-raised"
                alt="Raised Image"
                src="img/SPF70-Hole-Sponsor.png"
              />
              <h3 class="card-title">
                <small>$</small>
                250
              </h3>
            </card>
          </div>
          <div class="col-md-4">
            <card type="pricing" plain>
              <h3 class="category">SPF100 Level</h3>
              <img
                class="rounded img-raised"
                alt="Raised Image"
                src="img/SPF100-Hole-Sponsor.png"
              />
              <h3 class="card-title">
                <small>$</small>
                500
              </h3>
            </card>
          </div>
        </div>
      </div>
    </div>
    <!--     *********    END PRICING 4      *********      -->
  </div>
</template>
<script>
import { Card, Button } from "@/components";

export default {
  components: {
    Card,
    [Button.name]: Button
  },
  data() {
    return {
      pricing5Tab: "Expensive"
    };
  }
};
</script>

<style scoped></style>
