<template>
  <navbar position="fixed" type="default" menu-classes="ml-auto">
    <template slot-scope="{ toggle, isToggled }">
      <router-link
        v-popover:popover1
        class="navbar-brand"
        :to="{ name: 'home' }"
      >
        Todd Nagel Open
      </router-link>
    </template>
    <template slot="navbar-menu">
      <drop-down tag="li" title="Register">
        <router-link class="dropdown-item" :to="{ name: 'register' }"
          >Individual Registration</router-link
        >
        <router-link class="dropdown-item" :to="{ name: 'teamregister' }"
          >Team Registration</router-link
        >
      </drop-down>
      <!--<router-link v-popover:popover1 class="navbar-brand" to="/payment">
        Pay Online
      </router-link>-->
      <a href="https://www.toddnagelopen.com/payment" class="navbar-brand">
        Pay Online
      </a>
      <router-link
        v-popover:popover1
        class="navbar-brand"
        :to="{ name: 'eventPhotos' }"
      >
        Event Photos
      </router-link>
      <drop-down tag="li" title="How To Donate">
        <a
          href="https://give.skincancer.org/toddnagelopen"
          target="_blank"
          class="dropdown-item"
        >
          Online Donation Form
        </a>
        <router-link class="dropdown-item" :to="{ name: 'auctionitems' }"
          >2024 Silent Auction Items</router-link
        >
        <router-link class="dropdown-item" :to="{ name: 'holesponsorships' }"
          >Hole Sponsorships</router-link
        >
      </drop-down>
      <router-link v-popover:popover1 class="navbar-brand" to="/accommodations">
        Accommodations
      </router-link>
      <router-link
        v-popover:popover1
        class="navbar-brand"
        :to="{ name: 'contact' }"
      >
        Contact
      </router-link>
    </template>
  </navbar>
</template>

<script>
import { DropDown, Navbar } from "@/components";
import { Popover } from "element-ui";

export default {
  name: "main-navbar",
  components: {
    DropDown,
    Navbar,
    [Popover.name]: Popover
  }
};
</script>

<style scoped>
.navbar-nav li {
  margin-right: 10px;
}
</style>
