<template>
  <div>
    <div class="wrapper contact-page">
      <div class="page-header page-header-small">
        <parallax
          class="page-header-image"
          style="background-image: url('img/NagelBckgrd02.jpg')"
        >
        </parallax>
      </div>
      <div class="main">
        <div class="contact-content">
          <div class="container">
            <div class="row">
              <div class="col-md-12 ml-auto mr-auto">
                <h2 class="title">Auction Items</h2>
                <p class="description">
                  You can contact us with anything related to the Todd Nagel
                  Open. We'll get in touch with you as soon as possible.
                  <br />
                  <br />
                </p>
                <AuctionItem
                  v-for="auctionItem in auctionItems"
                  :key="auctionItem.auctionId"
                  :auctionItem="auctionItem"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuctionItem from "@/components/AuctionItem.vue";
import AuctionService from "@/services/AuctionService.js";

export default {
  name: "auctionItems",
  components: {
    AuctionItem
  },
  data() {
    return {
      auctionItems: []
    };
  },
  created() {
    AuctionService.getAuctionItems()
      .then(response => {
        this.auctionItems = response.data;
      })
      .catch(error => {
        console.log("There was an error:" + error);
      });
  }
};
</script>

<style scoped></style>
