<template>
  <div class="wrapper contact-page">
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/NagelBckgrd02.jpg')"
      >
      </parallax>
    </div>

    <div class="main">
      <div class="contact-content">
        <div class="container">
          <div class="row">
            <div class="col-md-5 ml-auto mr-auto">
              <h2 class="title">Send us a message</h2>
              <p>
                You can contact us with anything related to the Todd Nagel Open.
                We'll get in touch with you as soon as possible.
                <br />
                <br />
              </p>
            </div>
            <div class="col-md-5 ml-auto mr-auto">
              <info-section
                type="primary"
                class="mt-5"
                icon="now-ui-icons ui-1_email-85"
              >
                <h4 class="info-title">Email Us</h4>
                <p>
                  <strong
                    ><a href="mailto:caring@toddnagelopen.com"
                      >caring@toddnagelopen.com</a
                    ></strong
                  >
                </p>
              </info-section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { InfoSection } from "@/components";
export default {
  name: "contact-us",
  bodyClass: "contact-page",
  components: {
    InfoSection
  }
};
</script>
<style></style>
