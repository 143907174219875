<template>
  <div class="wrapper contact-page">
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/NagelBckgrd03.jpg')"
      >
      </parallax>
    </div>

    <div class="main">
      <div class="contact-content">
        <div class="container">
          <div class="row">
            <div class="col-md-7 ml-auto mr-auto">
              <div v-show="showSuccess">
                <h2>Success.</h2>
                <p><strong>You have been registered for the event.</strong></p>
                <p>
                  <strong
                    ><a
                      style="text-decoration:underline;"
                      href="https://www.toddnagelopen.com/payment"
                      >Save time at the event and Pay Online >></a
                    >
                  </strong>
                </p>
              </div>
              <div v-show="showError">
                <h2 class="text-danger">Error.</h2>
                <p>
                  <strong
                    >Oops. Something went wrong. Please try again later.</strong
                  >
                </p>
              </div>
              <h1 v-show="showForm" class="title">
                Individual Registration Form
              </h1>
              <form
                ref="registerForm"
                @submit.prevent="createSingleRegistration"
                v-show="showForm"
              >
                <label>First name*</label>
                <fg-input
                  placeholder="First Name..."
                  v-model="form.firstName"
                  addon-left-icon="now-ui-icons users_circle-08"
                  :class="{ 'text-danger': $v.form.firstName.$error }"
                  @blur="$v.form.firstName.$touch()"
                >
                </fg-input>
                <template v-if="$v.form.firstName.$error">
                  <p v-if="!$v.form.firstName.required" class="text-danger">
                    Please enter a First Name
                  </p>
                </template>

                <label>Last name*</label>
                <fg-input
                  placeholder="Last Name..."
                  v-model="form.lastName"
                  addon-left-icon="now-ui-icons users_circle-08"
                  :class="{ 'text-danger': $v.form.lastName.$error }"
                  @blur="$v.form.lastName.$touch()"
                >
                </fg-input>
                <template v-if="$v.form.lastName.$error">
                  <p v-if="!$v.form.lastName.required" class="text-danger">
                    Please enter a Last Name
                  </p>
                </template>

                <label>Email address</label>
                <fg-input
                  placeholder="Email Here..."
                  v-model="form.email"
                  addon-left-icon="now-ui-icons ui-1_email-85"
                  :class="{ 'text-danger': $v.form.email.$error }"
                  @blur="$v.form.email.$touch()"
                >
                </fg-input>
                <template v-if="$v.form.email.$error">
                  <p v-if="!$v.form.email.email" class="text-danger">
                    Please enter a valid email
                  </p>
                  <p v-if="!$v.form.email.required" class="text-danger">
                    Email is required
                  </p>
                </template>
                <div class="form-group">
                  <n-radio v-model="form.eventStatus" label="1"
                    >Lunch Only</n-radio
                  >
                  <n-radio v-model="form.eventStatus" label="0"
                    >I would like to golf, please place me on a team.</n-radio
                  >
                </div>
                <div class="form-group">
                  <label>Comments</label>
                  <textarea
                    v-model="form.comments"
                    name="comments"
                    class="form-control"
                    id="message"
                    rows="6"
                  ></textarea>
                </div>
                <div class="submit text-center">
                  <!--<input
                    type="submit"
                    class="btn btn-round btn-submit"
                    value="Submit Registration"
                    :disabled="$v.$invalid"
                  />-->
                  <button
                    :disabled="$v.$anyError"
                    type="submit"
                    class="btn btn-round btn-default"
                  >
                    Submit Registration
                  </button>
                  <p v-if="$v.$anyError" class="text-danger">
                    Please fill out the required field(s).
                  </p>
                </div>
              </form>
            </div>
            <!--<div class="col-md-5 ml-auto mr-auto">
              <info-section
                type="primary"
                class="mt-5"
                icon="now-ui-icons location_pin"
              >
                <h4 class="info-title">
                  Pay for registration before the event.
                </h4>
                <p>
                  We hav made it easier this year so you don't have to wait
                  in-line to pay for your golf. Use our online payment form.
                </p>
              </info-section>
            </div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  Button,
  //InfoSection,
  FormGroupInput,
  Checkbox,
  Radio
} from "@/components";
import { required, email } from "vuelidate/lib/validators";
export default {
  name: "register",
  bodyClass: "contact-page",
  components: {
    //InfoSection,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
    [Checkbox.name]: Checkbox,
    [Radio.name]: Radio
  },
  data() {
    return {
      form: this.createFreshRegisterObject(),
      showForm: true,
      showSuccess: false,
      showError: false
    };
  },
  validations: {
    form: {
      firstName: { required },
      lastName: { required },
      email: { required, email }
    }
  },
  methods: {
    createSingleRegistration() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (this.form.eventStatus === "1") {
          this.form.nonGolfer = true;
        } else if (this.form.eventStatus === "0") {
          this.form.nonGolfer = false;
        }

        this.$store
          .dispatch("createSingleRegistration", this.form)
          .then(() => {
            this.form = this.createFreshRegisterObject();
            this.showForm = false;
            this.showSuccess = true;
          })
          .catch(() => {
            this.showForm = false;
            this.showError = true;
            console.log("There was a problem with your registration");
          });
      }
    },
    createFreshRegisterObject() {
      return {
        firstName: "",
        lastName: "",
        email: "",
        eventStatus: "1",
        comments: ""
      };
    }
  }
};
</script>
<style scoped>
.page-header.page-header-small {
  min-height: 30vh;
  max-height: 400px;
}
.title {
  padding-top: 0px;
  margin-bottom: 10px;
}
</style>
