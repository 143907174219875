<template>
  <div>
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/NagelBckgrd01.jpg')"
      >
      </parallax>
      <div class="content-center">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto">
            <h1 class="title">Accommodations</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div class="info info-hover">
            <h4 class="info-title">Hampton Inn</h4>
            <p>
              4175 Dean Lakes Blvd<br />
              Shakopee, MN&nbsp; 55379<br />
              952-641-3600 <br />
              <a
                href="http://hamptoninn.hilton.com/en/hp/hotels/index.jhtml;jsessionid=IA4KVYWCPWM3ECSGBJF3MQQ?ctyhocn=MSPSKHX"
                title="http://www.countryinns.com/shakopeemn"
                target="_blank"
                >www.hamptoninn.com</a
              >
            </p>
            <p>10 minutes from golf course</p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="info info-hover">
            <h4 class="info-title">Holiday Inn Express & Suites</h4>
            <p>
              4550 12th Avenue East<br />
              Shakopee, MN&nbsp; 55379<br />
              1-800-HOLIDAY<br />
              952-445-22445<br />
              <a
                href="https://www.ihg.com/holidayinnexpress/hotels/us/en/shakopee/mspsh/hoteldetail"
                title="https://www.ihg.com/holidayinnexpress/hotels/us/en/shakopee/mspsh/hoteldetail"
                target="_blank"
                >Holiday Inn Express</a
              >
            </p>
            <p>Located off of Hwy 169</p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="info info-hover">
            <h4 class="info-title">Fairfield Inn & Suites</h4>
            <p>
              4600 12th Avenue East<br />
              Shakopee, MN&nbsp; 55379<br />
              612-248-1685<br />
              <a
                href="https://www.marriott.com/hotels/travel/mspfk-fairfield-inn-and-suites-minneapolis-shakopee"
                title="https://www.marriott.com/hotels/travel/mspfk-fairfield-inn-and-suites-minneapolis-shakopee"
                target="_blank"
                >Fairfield Inn</a
              >
            </p>
            <p>Located off of Hwy 169</p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="info info-hover">
            <h4 class="info-title">Mystic Lake Hotel</h4>
            <p>
              2400 Mystic Lake Blvd NW<br />
              Prior Lake, MN&nbsp; 55372<br />
              1-800-548-8536<br />
              <a
                href="http://www.mysticlake.com/"
                title="http://www.mysticlake.com/"
                target="_blank"
                >www.mysticlake.com</a
              >
            </p>
            <p>5 minutes from golf course</p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="info info-hover">
            <h4 class="info-title">Country Inn & Suites</h4>
            <p>
              1204 Ramsey Street<br />
              Shakopee, MN&nbsp; 55379<br />
              1-888-201-1746<br />
              952-445-0200<br />
              <a
                href="http://www.countryinns.com/shakopeemn"
                title="http://www.countryinns.com/shakopeemn"
                target="_blank"
                >www.countryinns.com</a
              >
            </p>
            <p>5 minutes from golf course</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Accommodations"
};
</script>

<style scoped>
.info {
  padding-top: 20px;
}
.page-header.page-header-small {
  min-height: 30vh;
  max-height: 400px;
}
</style>
