<template>
  <form @submit.prevent="submit">
    <input
      type="email"
      placeholder="What's your email"
      v-model="form.email"
      :class="{ error: $v.form.email.$error }"
      @blur="$v.form.email.$touch()"
    />
    <div v-if="$v.form.email.$error">
      <p v-if="!$v.form.email.email" class="errorMessage">
        Please enter a valid email
      </p>
      <p v-if="!$v.form.email.required" class="errorMessage">
        Email is required
      </p>
    </div>

    <button :disabled="$v.$invalid" type="submit">Submit</button>
    <p v-if="$v.$anyError" class="errorMessage">
      Please fill out the required fields
    </p>
  </form>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      form: {
        email: null
      }
    };
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        console.log("Form Submitted", this.form);
      }
    }
  },
  validations: {
    form: {
      email: {
        required,
        email
      }
    }
  }
};
</script>

<style scoped></style>
