<template>
  <div class="wrapper">
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/NagelBckgrd01.jpg')"
      >
      </parallax>
      <div class="content-center">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto">
            <h1 class="title">Event Photos</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="about-office">
      <div class="container">
        <div class="row">
          <div class="col-md-4">
            <img
              class="rounded img-raised"
              alt="Raised Image"
              src="img/event-photos/registration.jpg"
            />
          </div>
          <div class="col-md-4">
            <img
              class="rounded img-raised"
              alt="Raised Image"
              src="img/event-photos/practice-green.jpg"
            />
          </div>
          <div class="col-md-4">
            <img
              class="rounded img-raised"
              alt="Raised Image"
              src="img/event-photos/linda-ryan-pregolf.jpg"
            />
          </div>
          <div class="col-md-6">
            <img
              class="rounded img-raised"
              alt="Raised Image"
              src="img/event-photos/gathering-pregolf.jpg"
            />
          </div>
          <div class="col-md-6">
            <img
              class="rounded img-raised"
              alt="Raised Image"
              src="img/event-photos/foursome-06.jpg"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <img
              class="rounded img-raised"
              alt="Raised Image"
              src="img/event-photos/hole-sponsor-be-your-best.jpg"
            />
          </div>
          <div class="col-md-4">
            <img
              class="rounded img-raised"
              alt="Raised Image"
              src="img/event-photos/foursome-03.jpg"
            />
          </div>
          <div class="col-md-4">
            <img
              class="rounded img-raised"
              alt="Raised Image"
              src="img/event-photos/hole-sponsor-brian-mick.jpg"
            />
          </div>
          <div class="col-md-6">
            <img
              class="rounded img-raised"
              alt="Raised Image"
              src="img/event-photos/foursome-01.jpg"
            />
          </div>
          <div class="col-md-6">
            <img
              class="rounded img-raised"
              alt="Raised Image"
              src="img/event-photos/dass-golf.jpg"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <img
              class="rounded img-raised"
              alt="Raised Image"
              src="img/event-photos/shot-stop.jpg"
            />
          </div>
          <div class="col-md-4">
            <img
              class="rounded img-raised"
              alt="Raised Image"
              src="img/event-photos/Threesome-01.jpg"
            />
          </div>
          <div class="col-md-4">
            <img
              class="rounded img-raised"
              alt="Raised Image"
              src="img/event-photos/foursome-05.jpg"
            />
          </div>
          <div class="col-md-6">
            <img
              class="rounded img-raised"
              alt="Raised Image"
              src="img/event-photos/foursome-04.jpg"
            />
          </div>
          <div class="col-md-6">
            <img
              class="rounded img-raised"
              alt="Raised Image"
              src="img/event-photos/foursome-02.jpg"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <img
              class="rounded img-raised"
              alt="Raised Image"
              src="img/event-photos/Hole-Sponsor-Steve-Tracy.jpg"
            />
          </div>
          <div class="col-md-4">
            <img
              class="rounded img-raised"
              alt="Raised Image"
              src="img/event-photos/foursome-08.jpg"
            />
          </div>
          <div class="col-md-4">
            <img
              class="rounded img-raised"
              alt="Raised Image"
              src="img/event-photos/Hole-Sponsor-Linda-Ryan.jpg"
            />
          </div>
          <div class="col-md-6">
            <img
              class="rounded img-raised"
              alt="Raised Image"
              src="img/event-photos/foursome-07.jpg"
            />
          </div>
          <div class="col-md-6">
            <img
              class="rounded img-raised"
              alt="Raised Image"
              src="img/event-photos/foursome-010.jpg"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <img
              class="rounded img-raised"
              alt="Raised Image"
              src="img/event-photos/foursome-09.jpg"
            />
          </div>
          <div class="col-md-4">
            <img
              class="rounded img-raised"
              alt="Raised Image"
              src="img/event-photos/hole-sponsor-shop-group.jpg"
            />
          </div>
          <div class="col-md-4">
            <img
              class="rounded img-raised"
              alt="Raised Image"
              src="img/event-photos/foursome-011.jpg"
            />
          </div>
          <div class="col-md-6">
            <img
              class="rounded img-raised"
              alt="Raised Image"
              src="img/event-photos/foursome-012.jpg"
            />
          </div>
          <div class="col-md-6">
            <img
              class="rounded img-raised"
              alt="Raised Image"
              src="img/event-photos/foursome-013.jpg"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <img
              class="rounded img-raised"
              alt="Raised Image"
              src="img/event-photos/foursome-014.jpg"
            />
          </div>
          <div class="col-md-4">
            <img
              class="rounded img-raised"
              alt="Raised Image"
              src="img/event-photos/hole-sponsor-country.jpg"
            />
          </div>
          <div class="col-md-4">
            <img
              class="rounded img-raised"
              alt="Raised Image"
              src="img/event-photos/foursome-015.jpg"
            />
          </div>
          <div class="col-md-6">
            <img
              class="rounded img-raised"
              alt="Raised Image"
              src="img/event-photos/foursome-017.jpg"
            />
          </div>
          <div class="col-md-6">
            <img
              class="rounded img-raised"
              alt="Raised Image"
              src="img/event-photos/foursome-018.jpg"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <img
              class="rounded img-raised"
              alt="Raised Image"
              src="img/event-photos/foursome-016.jpg"
            />
          </div>
          <div class="col-md-4">
            <img
              class="rounded img-raised"
              alt="Raised Image"
              src="img/event-photos/dasss-dett.jpg"
            />
          </div>
          <div class="col-md-4">
            <img
              class="rounded img-raised"
              alt="Raised Image"
              src="img/event-photos/hailee.jpg"
            />
          </div>
          <div class="col-md-6">
            <img
              class="rounded img-raised"
              alt="Raised Image"
              src="img/event-photos/cake.jpg"
            />
          </div>
          <div class="col-md-6">
            <img
              class="rounded img-raised"
              alt="Raised Image"
              src="img/event-photos/auction-01.jpg"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <img
              class="rounded img-raised"
              alt="Raised Image"
              src="img/event-photos/auction-02.jpg"
            />
          </div>
          <div class="col-md-6">
            <img
              class="rounded img-raised"
              alt="Raised Image"
              src="img/event-photos/food-01.jpg"
            />
          </div>
          <div class="col-md-6">
            <img
              class="rounded img-raised"
              alt="Raised Image"
              src="img/event-photos/food-02.jpg"
            />
          </div>
          <div class="col-md-6">
            <img
              class="rounded img-raised"
              alt="Raised Image"
              src="img/event-photos/cake-linda-ryan.jpg"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "about-us",
  bodyClass: "about-us"
};
</script>

<style scoped>
.page-header.page-header-small {
  min-height: 30vh;
  max-height: 400px;
}
.about-us .about-office {
  padding: 20px 0px;
}
</style>
