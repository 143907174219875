<template>
  <div>
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/NagelBckgrd01.jpg')"
      >
      </parallax>
      <div class="content-center">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto">
            <h1 class="title">Pay Online</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-12 ml-auto mr-auto">
          <h1 class="title">
            Enter Payment Information
          </h1>
          <p style="font-weight:bold;">
            <span class="text-danger">Important!</span> <br />Enter your first
            name, last name and email. Otherwise your payment will fail and you
            will not receive a confirmation email.
          </p>
          <div>
            <form ref="paymentForm" @submit.prevent="createPayment">
              <label>First name*</label>
              <fg-input
                placeholder="First Name..."
                v-model="firstName"
                addon-left-icon="now-ui-icons users_circle-08"
                class="text-danger"
              >
              </fg-input>

              <label>Last name*</label>
              <fg-input
                placeholder="Last Name..."
                v-model="lastName"
                addon-left-icon="now-ui-icons users_circle-08"
                class="text-danger"
              >
              </fg-input>
              <label>Email address</label>
              <fg-input
                placeholder="Email Here..."
                v-model="email"
                addon-left-icon="now-ui-icons ui-1_email-85"
                class="text-danger"
              >
              </fg-input>
            </form>
          </div>
          <div id="listContainer">
            <p>Select Items:</p>
            <div class="row">
              <div class="col-sm-4">
                <p><strong>Non-Golfers</strong></p>
                <div
                  v-for="(item, index) in NonGolferItems"
                  :key="item.name"
                  :id="'item_' + index"
                >
                  <label class="container" :for="item.name"
                    >{{ item.name }}
                    <input
                      type="checkbox"
                      v-model="selectedItems"
                      :id="item.name"
                      :value="item"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
              <div class="col-sm-4">
                <p><strong>Golfers</strong></p>
                <div
                  v-for="(item, index) in GolferItems"
                  :key="item.name"
                  :id="'item_' + index"
                >
                  <label class="container" :for="item.name"
                    >{{ item.name }}
                    <input
                      type="checkbox"
                      v-model="selectedItems"
                      :id="item.name"
                      :value="item"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
              <div class="col-sm-4">
                <p><strong>Hole Sponsorships</strong></p>
                <div
                  v-for="(item, index) in SponsorItems"
                  :key="item.name"
                  :id="'item_' + index"
                >
                  <label class="container" :for="item.name"
                    >{{ item.name }}
                    <input
                      type="checkbox"
                      v-model="selectedItems"
                      :id="item.name"
                      :value="item"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>

            <div id="totalSelected" style="font-size:18px;">
              <strong>Total: {{ selectedTotal | toCurrency }}</strong>
            </div>
            <input hidden id="order-amount" :value="selectedTotal" />
            <input
              hidden
              id="order-reference"
              :value="orderDate + '_' + lastName + '-' + firstName"
            />
            <input hidden id="order-description" :value="orderDescription" />
          </div>

          <SquareWebPaymentSdk
            ref="paymentForm"
            :amount="selectedTotal"
            :orderreference="orderDate + '_' + lastName + '-' + firstName"
            :orderdetails="orderDescription"
            :payerFirstName="firstName"
            :payerLastName="lastName"
            :payerEmail="email"
          ></SquareWebPaymentSdk>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SquareWebPaymentSdk from "../components/Square/SquareWebPaymentSdk.vue";
import {
  //InfoSection,
  Button,
  Checkbox,
  FormGroupInput
} from "@/components";
import { required } from "vuelidate/lib/validators";
export default {
  name: "payment",
  components: {
    SquareWebPaymentSdk,
    [Checkbox.name]: Checkbox,
    [FormGroupInput.name]: FormGroupInput,
    [Button.name]: Button
  },
  data: function() {
    return {
      pageReady: false,
      NonGolferItems: [
        {
          name: "$50 | Non-Golfer",
          value: 50
        },
        {
          name: "$100 | Two Non-Golfers",
          value: 100
        }
      ],
      GolferItems: [
        {
          name: "$125 | One Individual Golfer",
          value: 125
        },
        {
          name: "$250 | Two Individual Golfers",
          value: 250
        },
        {
          name: "$375 | Three Individual Golfers",
          value: 375
        },
        {
          name: "$500 | Four Individual Golfers",
          value: 500
        },
        {
          name: "$10 | One Team Mulligan",
          value: 10
        },
        {
          name: "$20 | Two Team Mulligans",
          value: 20
        },
        {
          name: "$30 | Three Team Mulligans",
          value: 30
        },
        {
          name: "$40 | Five Team Mulligans",
          value: 40
        }
      ],
      SponsorItems: [
        {
          name: "$100 | Hole Sponsorship SPF50",
          value: 100
        },
        {
          name: "$250 | Hole Sponsorship SPF70",
          value: 250
        },
        {
          name: "$500 | Hole Sponsorship SPF100",
          value: 500
        }
      ],
      selectedItems: [],
      selectedTotal: 0,
      orderDate: new Date().toISOString().slice(0, 10),
      orderDescription: "",
      firstName: "",
      lastName: "",
      email: "",
      showProcessingPayment: false
    };
  },
  beforeUpdate() {
    this.pageReady = true;
  },
  validations: {
    form: {
      firstName: { required },
      lastName: { required }
    }
  },
  methods: {
    createPayment() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.showCreditCardInfo = true;
      }
    }
  },
  watch: {
    selectedItems: function() {
      this.selectedTotal = 0;
      this.orderDescription = "";
      for (let i = 0; i < this.selectedItems.length; i++) {
        this.selectedTotal = this.selectedTotal + this.selectedItems[i].value;
        this.orderDescription =
          this.orderDescription + "<br />" + this.selectedItems[i].name;
      }
    }
  },
  filters: {
    toCurrency: function(value) {
      if (typeof value !== "number") {
        return value;
      }
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2
      });
      return formatter.format(value);
    }
  }
};
</script>

<style scoped>
.info {
  padding-top: 20px;
}
.page-header.page-header-small {
  min-height: 30vh;
  max-height: 400px;
}
/* The container */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  /*font-size: 22px;*/
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* Square Web Payment SDK Styles */
</style>
