import { render, staticRenderFns } from "./HoleSponsorships.vue?vue&type=template&id=652136a9&scoped=true"
import script from "./HoleSponsorships.vue?vue&type=script&lang=js"
export * from "./HoleSponsorships.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "652136a9",
  null
  
)

export default component.exports