import axios from "axios";

const apiClient = axios.create({
  baseURL: "/api",
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});

export default {
  postInvidualRegistration(newSingleRegistration) {
    return apiClient.post("/singleRegistration", newSingleRegistration);
  },
  postTeamRegistration(newTeamRegistration) {
    return apiClient.post("/teamRegistration", newTeamRegistration);
  }
};
