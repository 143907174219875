<template>
  <footer
    class="footer"
    :class="{ [`footer-${type}`]: type }"
    :data-background-color="backgroundColor"
  >
    <div class="container">
      <nav>
        <ul>
      <li><router-link
        v-popover:popover1
        class="navbar-brand"
        :to="{ name: 'home' }"
      >
        Todd Nagel Open
      </router-link></li>
          <li>
      <router-link
        v-popover:popover1
        class="navbar-brand"
        :to="{ name: 'register' }"
      >
        Register
      </router-link>
          </li>
          <li>
      <router-link v-popover:popover1 class="navbar-brand" :to="{ name: 'contact'}">
        Contact
      </router-link>
          </li>
        </ul>
      </nav>
      <div class="copyright">
        &copy; {{ year }}
        <a href="https:///www.toddnagelopen.com" target="_blank">Todd Nagel Open</a>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  props: {
    backgroundColor: String,
    type: String
  },
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style></style>
