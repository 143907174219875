<template>
  <div>
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/NagelBckgrd01.jpg')"
      >
      </parallax>
      <div class="content-center">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto">
            <h1 class="title">Success</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-12 ml-auto mr-auto">
          <h2 class="title">
            Your payment has been received.
          </h2>
          <h3>
            Thank you for your support.
          </h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "payment-success",
  data: function() {
    return {};
  },
  mounted() {}
};
</script>

<style scoped>
.info {
  padding-top: 20px;
}
.page-header.page-header-small {
  min-height: 30vh;
  max-height: 400px;
}
</style>
