<template>
  <div class="wrapper">
    <div class="page-header page-header-medium">
      <parallax
        class="page-header-image"
        style="background-image: url('img/NagelBg-Logo.jpg');"
      ></parallax>
    </div>
    <div class="container">
      <div class="section-story-overview">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">18th Annual Todd Nagel Open</h2>
            <p>
              As a tribute to Todd in remembrance of what a wonderful person he
              was and how he lives on in our hearts, I am happy to announce the
              18th Annual Todd Nagel Open. It will and always has been a fun
              filled day where we enjoy one of the activities Todd loved most
              while raising money for the Skin Cancer Foundation.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 ml-auto mr-auto">
            <dl class="dl-horizontal">
              <dt>Date:</dt>
              <dd>Sunday, September 8, 2024</dd>
              <dt>Location:</dt>
              <dd>
                Stonebrooke Golf Course, Shakopee, MN
                <a
                  href="https://www.google.com/maps/dir//Stonebrooke+Golf+Club,+2693+Co+Rd+79,+Shakopee,+MN+55379/@44.7495557,-93.5192342,16.75z/data=!4m8!4m7!1m0!1m5!1m1!1s0x87f6112f81c5cec5:0xe00c9b4ed5fae7cd!2m2!1d-93.5199126!2d44.7503925"
                  target="_blank"
                >
                  Directions to Course
                </a>
              </dd>
              <dt>Time:</dt>
              <dd>9:00 am shotgun start (scramble)</dd>
              <dt>Cost:</dt>
              <dd>
                $125.00 per person/golfers
                <br />
                $50.00 per person/non-golfers
                <br />
                Cost includes 18 holes of golf and mid-afternoon lunch ~ 2:00 pm
              </dd>
            </dl>
          </div>
          <div class="col-md-4 ml-auto mr-auto">
            <dl class="dl-horizontal">
              <dt>Open House:</dt>
              <dd>
                For anybody interested there will be a Open House at Linda's,
                Saturday, September 7, beginning at 5 p.m.
                <br />
                Please email us at,
                <a href="mailto:caring@toddnagelopen.com">
                  caring@toddnagelopen.com
                </a>
                if you are planning on attending.
              </dd>
            </dl>
            <dl class="dl-horizontal">
              <dt>Save Time at the Event and PAY ONLINE!</dt>
              <dd>
                <a
                  class="submit text-center btn"
                  href="https://www.toddnagelopen.com/payment"
                >
                  Go to Online Payment Form
                </a>
              </dd>
            </dl>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <!-- First image on the left side -->
            <div
              class="image-container image-left"
              style="background-image: url('img/Linda-Ryan-2019.jpg');"
            >
              <p class="blockquote blockquote-primary">
                "Thanks to everyone that has supported and continues to support
                this great event."
                <br />
                <small>-Linda Nagel</small>
              </p>
            </div>
          </div>
          <div class="col-md-5">
            <!-- First image on the right side, above the article -->
            <div
              class="image-container image-right"
              style="background-image: url('img/2019-Pre-TeeOff.jpg');"
            ></div>
            <h3>Remembering. Celebrating. Supporting.</h3>
            <p>
              In the last 17 years, your support of the golf event and
              fundraising drive has raised well over a half million dollars! The
              Skin Cancer Foundation continues to award research grants to
              encourage innovation in prevention, early detection and treatment
              of skin cancer. The 2021 program initiated new ideas and
              technologies across the spectrum of skin cancers, including
              melanoma for which the $50,000 research grant was awarded in
              Todd’s name. We are incredibly grateful to continue supporting
              research grants through the Skin Cancer Foundation to fuel these
              projects.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-8 ml-auto mr-auto text-center">
          <h2 class="title">Important items you need to do</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <card
            type="background"
            raised
            color="transparent"
            style="background-image: url('img/auction-items.jpg');"
          >
            <template slot="info">
              <div class="icon icon-white">
                <i class="now-ui-icons business_bulb-63"></i>
              </div>
              <div class="description">
                <h4 class="info-title">Auction Items</h4>
                <p>
                  Preview the list of items that will be available for you to
                  bid on.
                </p>
                <strong>
                  <router-link class="ml-3" :to="{ name: 'auctionitems' }">
                    Preview the items
                  </router-link>
                </strong>
              </div>
            </template>
          </card>
        </div>
        <div class="col-md-4">
          <card
            type="background"
            raised
            color="transparent"
            style="background-image: url('img/online-registration.jpg');"
          >
            <template slot="info">
              <div class="icon icon-white">
                <i class="now-ui-icons business_badge"></i>
              </div>
              <div class="description">
                <h4 class="info-title">Online Registration</h4>
                <p>
                  Have more time to grab drinks and socialize by registering
                  online.
                </p>
                <strong>
                  <router-link
                    v-popover:popover1
                    class="ml-3"
                    :to="{ name: 'register' }"
                  >
                    Individual Registration
                  </router-link>
                </strong>
                <strong>
                  <router-link
                    v-popover:popover1
                    class="ml-3"
                    :to="{ name: 'teamregister' }"
                  >
                    Team Registration
                  </router-link>
                </strong>
              </div>
            </template>
          </card>
        </div>
        <div class="col-md-4">
          <card
            type="background"
            raised
            color="transparenr"
            style="background-image: url('img/schrage-sponsorship.jpg');"
          >
            <template slot="info">
              <div class="icon icon-white">
                <i class="now-ui-icons location_map-big"></i>
              </div>
              <div class="description">
                <h4 class="info-title">Hole Sponsorships</h4>
                <p>
                  Each sign is personalized to your individual or business
                  needs.
                </p>
                <strong>
                  <router-link class="ml-3" :to="{ name: 'holesponsorships' }">
                    Yes, I want to sponsor a hole
                  </router-link>
                </strong>
              </div>
            </template>
          </card>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-10 ml-auto mr-auto">
          <card type="blog" plain>
            <div class="row">
              <div class="col-md-7">
                <h3>
                  Take Responsibility for Your Skin.
                </h3>
                <ul>
                  <li>
                    <strong>Seek the shade</strong>
                    , especially between 10 AM and 4 PM.
                  </li>
                  <li><strong>Do not burn.</strong></li>
                  <li>
                    <strong>
                      Avoid tanning and never use UV tanning beds.
                    </strong>
                  </li>
                  <li>
                    <strong>Cover up</strong>
                    with clothing, including a broad-brimmed hat and UV-blocking
                    sunglasses.
                  </li>
                  <li>
                    <strong>Use a broad spectrum (UVA/UVB) sunscreen</strong>
                    with an SPF of 15 or higher every day. For extended outdoor
                    activity, use a water-resistant, broad spectrum (UVA/UVB)
                    sunscreen with an SPF of 30 or higher.
                  </li>
                  <li>
                    <strong>Apply 1 ounce</strong>
                    (2 tablespoons) of sunscreen to your entire body 30 minutes
                    before going outside. Reapply every two hours or immediately
                    after swimming or excessive sweating.
                  </li>
                  <li>
                    <strong>Keep newborns out of the sun.</strong>
                    Sunscreens should be used on babies over the age of six
                    months.
                  </li>
                  <li>
                    <strong>Examine your skin</strong>
                    head-to-toe every month.
                  </li>
                </ul>
              </div>
              <div class="col-md-5">
                <div class="card-image">
                  <img
                    class="img img-raised rounded"
                    src="img/apply-sunscreen.jpg"
                  />
                </div>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Card } from '@/components'
export default {
  components: {
    Card,
  },
}
</script>
<style scoped>
.starter-page {
  min-height: calc(100vh - 79px);
}
.title {
  padding-top: 0px;
  margin-bottom: 10px;
}
</style>
