<template>
  <div class="wrapper contact-page">
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/NagelBckgrd03.jpg')"
      >
      </parallax>
    </div>

    <div class="main">
      <div class="contact-content">
        <div class="container">
          <div class="row">
            <div class="col-md-12 ml-auto mr-auto">
              <div v-show="showSuccess">
                <h2>Success.</h2>
                <p><strong>You have been registered for the event.</strong></p>
                <p>
                  <strong
                    ><a
                      style="text-decoration:underline;"
                      href="https://www.toddnagelopen.com/payment"
                      >Save time at the event and Pay Online >></a
                    >
                  </strong>
                </p>
              </div>
              <div v-show="showError">
                <h2 class="text-danger">Error.</h2>
                <p>
                  <strong
                    >Oops. Something went wrong. Please try again later.</strong
                  >
                </p>
              </div>
              <h1 v-show="showForm" class="title">
                Team Registration Form
              </h1>
              <form
                ref="registerForm"
                @submit.prevent="createTeamRegistration"
                v-show="showForm"
              >
                <div class="card-body">
                  <h3 class="card-title">Team Name</h3>
                  <fg-input
                    placeholder="Team Name..."
                    v-model="form.teamName"
                    addon-left-icon="now-ui-icons users_circle-08"
                    :class="{ 'text-danger': $v.form.teamName.$error }"
                    @blur="$v.form.teamName.$touch()"
                  >
                  </fg-input>
                  <template v-if="$v.form.teamName.$error">
                    <p v-if="!$v.form.teamName.required" class="text-danger">
                      Please enter a Team Name
                    </p>
                  </template>
                </div>
                <!-- Golfer One -->
                <div class="card-body">
                  <h3 class="card-title">Golfer 1</h3>
                  <label>First name</label>
                  <fg-input
                    placeholder="First Name..."
                    v-model="form.player1FirstName"
                    addon-left-icon="now-ui-icons users_circle-08"
                    :class="{
                      'text-danger': $v.form.player1FirstName.$error
                    }"
                    @blur="$v.form.player1FirstName.$touch()"
                  >
                  </fg-input>
                  <template v-if="$v.form.player1FirstName.$error">
                    <p
                      v-if="!$v.form.player1FirstName.required"
                      class="text-danger"
                    >
                      Please enter a First name
                    </p>
                  </template>

                  <label>Last name</label>
                  <fg-input
                    placeholder="Last Name..."
                    v-model="form.player1LastName"
                    addon-left-icon="now-ui-icons users_circle-08"
                    :class="{ 'text-danger': $v.form.player1LastName.$error }"
                    @blur="$v.form.player1LastName.$touch()"
                  >
                  </fg-input>
                  <template v-if="$v.form.player1LastName.$error">
                    <p
                      v-if="!$v.form.player1LastName.required"
                      class="text-danger"
                    >
                      Please enter a Last name
                    </p>
                  </template>

                  <label>Email address</label>
                  <fg-input
                    placeholder="Email Here..."
                    v-model="form.player1Email"
                    addon-left-icon="now-ui-icons ui-1_email-85"
                    :class="{ 'text-danger': $v.form.player1Email.$error }"
                    @blur="$v.form.player1Email.$touch()"
                  >
                  </fg-input>
                  <template v-if="$v.form.player1Email.$error">
                    <p v-if="!$v.form.player1Email.email" class="text-danger">
                      Please enter a valid email
                    </p>
                    <p
                      v-if="!$v.form.player1Email.required"
                      class="text-danger"
                    >
                      Please enter an Email
                    </p>
                  </template>
                </div>
                <!-- Golfer One End -->
                <!-- Golfer Two -->
                <div class="card-body">
                  <h3 class="card-title">Golfer 2</h3>
                  <label>First name</label>
                  <fg-input
                    placeholder="First Name..."
                    v-model="form.player2FirstName"
                    addon-left-icon="now-ui-icons users_circle-08"
                    :class="{
                      'text-danger': $v.form.player2FirstName.$error
                    }"
                    @blur="$v.form.player2FirstName.$touch()"
                  >
                  </fg-input>
                  <template v-if="$v.form.player2FirstName.$error">
                    <p
                      v-if="!$v.form.player2FirstName.required"
                      class="text-danger"
                    >
                      Please enter a First name
                    </p>
                  </template>

                  <label>Last name</label>
                  <fg-input
                    placeholder="Last Name..."
                    v-model="form.player2LastName"
                    addon-left-icon="now-ui-icons users_circle-08"
                    :class="{ 'text-danger': $v.form.player2LastName.$error }"
                    @blur="$v.form.player2LastName.$touch()"
                  >
                  </fg-input>
                  <template v-if="$v.form.player2LastName.$error">
                    <p
                      v-if="!$v.form.player2LastName.required"
                      class="text-danger"
                    >
                      Please enter a Last name
                    </p>
                  </template>

                  <label>Email address</label>
                  <fg-input
                    placeholder="Email Here..."
                    v-model="form.player2Email"
                    addon-left-icon="now-ui-icons ui-1_email-85"
                    :class="{ 'text-danger': $v.form.player2Email.$error }"
                    @blur="$v.form.player2Email.$touch()"
                  >
                  </fg-input>
                  <template v-if="$v.form.player2Email.$error">
                    <p v-if="!$v.form.player2Email.email" class="text-danger">
                      Please enter a valid email
                    </p>
                    <p
                      v-if="!$v.form.player2Email.required"
                      class="text-danger"
                    >
                      Please enter an Email
                    </p>
                  </template>
                </div>
                <!-- Golfer Two End -->
                <!-- Golfer Three -->
                <div class="card-body">
                  <h3 class="card-title">Golfer 3</h3>
                  <label>First name</label>
                  <fg-input
                    placeholder="First Name..."
                    v-model="form.player3FirstName"
                    addon-left-icon="now-ui-icons users_circle-08"
                  >
                  </fg-input>

                  <label>Last name</label>
                  <fg-input
                    placeholder="Last Name..."
                    v-model="form.player3LastName"
                    addon-left-icon="now-ui-icons users_circle-08"
                  >
                  </fg-input>

                  <label>Email address</label>
                  <fg-input
                    placeholder="Email Here..."
                    v-model="form.player3Email"
                    addon-left-icon="now-ui-icons ui-1_email-85"
                    :class="{ 'text-danger': $v.form.player3Email.$error }"
                    @blur="$v.form.player3Email.$touch()"
                  >
                  </fg-input>
                  <template v-if="$v.form.player3Email.$error">
                    <p v-if="!$v.form.player3Email.email" class="text-danger">
                      Please enter a valid email
                    </p>
                  </template>
                </div>
                <!-- Golfer Three End -->
                <!-- Golfer Four -->
                <div class="card-body">
                  <h3 class="card-title">Golfer 4</h3>
                  <label>First name</label>
                  <fg-input
                    placeholder="First Name..."
                    v-model="form.player4FirstName"
                    addon-left-icon="now-ui-icons users_circle-08"
                  >
                  </fg-input>

                  <label>Last name</label>
                  <fg-input
                    placeholder="Last Name..."
                    v-model="form.player4LastName"
                    addon-left-icon="now-ui-icons users_circle-08"
                  >
                  </fg-input>

                  <label>Email address</label>
                  <fg-input
                    placeholder="Email Here..."
                    v-model="form.player4Email"
                    addon-left-icon="now-ui-icons ui-1_email-85"
                    :class="{ 'text-danger': $v.form.player4Email.$error }"
                    @blur="$v.form.player4Email.$touch()"
                  >
                  </fg-input>
                  <template v-if="$v.form.player4Email.$error">
                    <p v-if="!$v.form.player4Email.email" class="text-danger">
                      Please enter a valid email
                    </p>
                  </template>
                </div>
                <!-- Golfer Four End -->
                <div class="form-group">
                  <label>Comments</label>
                  <textarea
                    v-model="form.comments"
                    name="comments"
                    class="form-control"
                    id="message"
                    rows="6"
                  ></textarea>
                </div>
                <div class="submit text-center">
                  <button
                    :disabled="$v.$anyError"
                    type="submit"
                    class="btn btn-round btn-default"
                  >
                    Submit Team Registration
                  </button>
                  <p v-if="$v.$anyError" class="text-danger">
                    Please fill out the required field(s).
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Button, FormGroupInput } from "@/components";
import { required, email } from "vuelidate/lib/validators";
export default {
  name: "teamregister",
  bodyClass: "contact-page",
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput
  },
  data() {
    return {
      form: this.createFreshRegisterObject(),
      showForm: true,
      showSuccess: false,
      showError: false
    };
  },
  validations: {
    form: {
      teamName: { required },
      player1FirstName: { required },
      player1LastName: { required },
      player1Email: { required, email },
      player2FirstName: { required },
      player2LastName: { required },
      player2Email: { required, email },
      player3Email: { email },
      player4Email: { email }
    }
  },
  methods: {
    createTeamRegistration() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$store
          .dispatch("createTeamRegistration", this.form)
          .then(() => {
            this.form = this.createFreshRegisterObject();
            this.showForm = false;
            this.showSuccess = true;
          })
          .catch(() => {
            this.showForm = false;
            this.showError = true;
            console.log("There was a problem with your registration");
          });
      }
    },
    createFreshRegisterObject() {
      return {
        teamName: "",
        player1FirstName: "",
        player1LastName: "",
        player1Email: "",
        player2FirstName: "",
        player2LastName: "",
        player2Email: "",
        player3FirstName: "",
        player3LastName: "",
        player3Email: "",
        player4FirstName: "",
        player4LastName: "",
        player4Email: "",
        comments: ""
      };
    }
  }
};
</script>
<style scoped>
.page-header.page-header-small {
  min-height: 30vh;
  max-height: 400px;
}
.title {
  padding-top: 0px;
  margin-bottom: 10px;
}
</style>
