import Vue from "vue";
import Vuex from "vuex";
import RegisterService from "@/services/RegisterService.js";
import EmailService from "@/services/EmailService.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    singleRegistrationList: []
  },
  mutations: {
    ADD_SINGLE_REGISTRATION(state, singleRegistration) {
      state.singleRegistrationList.push(singleRegistration);
    }
  },
  actions: {
    createSingleRegistration({ commit }, singleRegistration) {
      return RegisterService.postInvidualRegistration(singleRegistration).then(
        () => {
          commit("ADD_SINGLE_REGISTRATION", singleRegistration);
        }
      );
    },
    createTeamRegistration({ commit }, teamRegistration) {
      return RegisterService.postTeamRegistration(teamRegistration).then(() => {
        commit("ADD_SINGLE_REGISTRATION", teamRegistration);
      });
    },
    emailPaymentSuccess({ commit }, emailRequestDto) {
      return EmailService.postPaymentSuccessEmail(emailRequestDto).then(() => {
        //commit("ADD_SINGLE_REGISTRATION", teamRegistration);
      });
    }
  },
  modules: {}
});
