import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
import StarterNavbar from "./layout/StarterNavbar.vue";
import StarterFooter from "./layout/StarterFooter.vue";
import EventDetails from "./views/EventDetails.vue";
import ContactUs from "./views/ContactUs.vue";
import Register from "./views/Register.vue";
import TeamRegister from "./views/TeamRegister.vue";
import Accommodations from "./views/Accommodations.vue";
import Payment from "./views/Payment.vue";
import PaymentSuccess from "./views/Payment-Success.vue";
import AuctionItems from "./views/AuctionItems.vue";
import HoleSponsorships from "./views/HoleSponsorships.vue";
import Example from "./views/Example.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    { path: "/example", component: Example },
    {
      path: "/",
      name: "home",
      components: {
        default: Home,
        header: StarterNavbar,
        footer: StarterFooter
      },
      props: {
        footer: { backgroundColor: "black" }
      }
    },
    {
      path: "/register",
      name: "register",
      components: {
        default: Register,
        header: StarterNavbar,
        footer: StarterFooter
      },
      props: {
        footer: { backgroundColor: "black" }
      }
    },
    {
      path: "/teamregister",
      name: "teamregister",
      components: {
        default: TeamRegister,
        header: StarterNavbar,
        footer: StarterFooter
      },
      props: {
        footer: { backgroundColor: "black" }
      }
    },
    {
      path: "/payment",
      name: "payment",
      components: {
        default: Payment,
        header: StarterNavbar,
        footer: StarterFooter
      },
      props: {
        footer: { backgroundColor: "black" }
      }
    },
    {
      path: "/payment-success",
      name: "paymentSuccess",
      components: {
        default: PaymentSuccess,
        header: StarterNavbar,
        footer: StarterFooter
      },
      props: {
        footer: { backgroundColor: "black" }
      }
    },
    {
      path: "/eventPhotos",
      name: "eventPhotos",
      components: {
        default: EventDetails,
        header: StarterNavbar,
        footer: StarterFooter
      },
      props: {
        footer: { backgroundColor: "black" }
      }
    },
    {
      path: "/accommodations",
      name: "accommodations",
      components: {
        default: Accommodations,
        header: StarterNavbar,
        footer: StarterFooter
      },
      props: {
        footer: { backgroundColor: "black" }
      }
    },
    {
      path: "/contact",
      name: "contact",
      components: {
        default: ContactUs,
        header: StarterNavbar,
        footer: StarterFooter
      },
      props: {
        footer: { backgroundColor: "black" }
      }
    },
    {
      path: "/auction-items",
      name: "auctionitems",
      components: {
        default: AuctionItems,
        header: StarterNavbar,
        footer: StarterFooter
      },
      props: {
        footer: { backgroundColor: "black" }
      }
    },
    {
      path: "/hole-sponsorships",
      name: "holesponsorships",
      components: {
        default: HoleSponsorships,
        header: StarterNavbar,
        footer: StarterFooter
      },
      props: {
        footer: { backgroundColor: "black" }
      }
    }
  ],
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
});
